<template>
  <div>
    <!-- Filters -->
    <!-- <users-list-filters
      :sort-by-filter.sync="sortByFilter"
      :sort-direction-filter.sync="sortDirectionFilter"
      :sort-by-options="sortByOptions"
      :sort-direction-options="sortDirectionOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t("Mostrar") }}</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="pageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t("entradas") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                :placeholder="this.$i18n.t('Buscar...')" />
              <!-- <b-button variant="primary" @click="() => (openModalDeposit = true)">
                <span class="text-nowrap">{{
                  $t("Adicionar / Remover saldo")
                }}</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table class="position-relative" striped responsive :items="items" :fields="fields" show-empty
        :empty-text="this.$i18n.t('Nenhum registro correspondente encontrado')">
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="renderImgAvatar(data.item.avatar)" :text="avatarText(data.item.fullname)" />
            </template>
            <b-link :to="{
              name: `${routerUserView}`,
              params: { id: data.item._id },
            }" class="font-weight-bold d-block limitString" v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary">
              {{ data.item.fullname }}
            </b-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>

        <template #cell(sponsoredLicense)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ moneyFormat(data.item.traderSummaries ? data.item.traderSummaries.reduce((sum, item) => {
                  return sum + item.sponsoredList.reduce((sum, item) => {
                    return item.sponsoredType === 'license' || item.sponsoredType === 'licenseContribution' ? sum + (item.licenseValue > 0 ? item.licenseValue : 0) :
                    sum;
                  }, 0)
                }, 0) : 0) }}
              </span>
          </div>
        </template>

        <template #cell(sponsoredContribution)="data">
          <div class="text-nowrap">
            <span class="align-text-top">
              {{ moneyFormat(data.item.traderSummaries ? data.item.traderSummaries.reduce((sum, item) => {
                  return sum + item.sponsoredList.reduce((sum, item) => {
                    return item.sponsoredType === 'licenseContribution' || item.sponsoredType === 'contribution' ? sum + (item.initialValue > 0 ? item.initialValue : 0) :
                    sum;
                  }, 0)
                }, 0) : 0) }}
            </span>
          </div>
        </template>

        <template #cell(pixLicense)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-success">
              {{ moneyFormat(data.item.traderSummaries ? data.item.traderSummaries.reduce((sum, item) => {
                  return sum + item.sponsoredList.reduce((sum, item) => {
                    return item.sponsoredType === 'licensePIX' || item.sponsoredType === 'licenseContributionPIX' ? sum + (item.licenseValue > 0 ? item.licenseValue : 0) :
                    sum;
                  }, 0)
                }, 0) : 0) }}
            </span>
          </div>
        </template>

        <template #cell(pixContribution)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-success">
              {{ moneyFormat(data.item.traderSummaries ? data.item.traderSummaries.reduce((sum, item) => {
                  return sum + item.sponsoredList.reduce((sum, item) => {
                    return item.sponsoredType === 'licenseContributionPIX' || item.sponsoredType === 'contributionPIX' ? sum + (item.initialValue > 0 ? item.initialValue : 0) :
                    sum;
                  }, 0)
                }, 0) : 0) }}
            </span>
          </div>
        </template>

        <template #cell(borrowedContribution)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-info">
              {{ moneyFormat(data.item.traderSummaries ? data.item.traderSummaries.reduce((sum, item) => {
                  return sum + item.sponsoredList.reduce((sum, item) => {
                    return item.sponsoredType === 'borrowedContribution' ? sum + (item.initialValue > 0 ? item.initialValue : 0) :
                    sum;
                  }, 0)
                }, 0) : 0) }}
            </span>
          </div>
        </template>        

        <template #cell(removeBalance)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-danger">
              <!-- {{
              moneyFormat(
                data.item.addRemoveBalance.reduce((sum, item) => {
                  return item.value < 0 ? sum + item.value : sum;
                }, 0)) 
                }} -->
                </span>
          </div>
        </template>

        <template #cell(lastUpdate)="data">
          <div class="text-nowrap">
            <span class="align-text-top" v-if="data.item.traderSummaries.length > 0">
              {{ format(new Date(getMostRecentSponsoredDate(data.item.traderSummaries)), 'dd/MM/yyyy HH:mm') }}
            </span>
          </div>
        </template>

        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              format(new Date(data.item.createdAt), "dd/MM/yyyy")
            }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-button variant="outline-primary" size="sm" @click="() => addRemoveBalanceModal(data.item)">
              {{ $t("Patrocinar | Ativar") }}
            </b-button>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">{{ $t("Mostrando") }} {{ entriesFrom }} {{ $t("a") }}
              {{ entriesTo }} {{ $t("de") }} {{ totalRows }}
              {{ $t("entradas") }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal id="modal-add-remove-balance" :title="userToAddRemoveBalance.fullname" hide-footer no-close-on-backdrop
      centered>
      <div>
        <div class="mb-1">{{ $t("Informe o valor") }}:</div>
        <b-form-input v-model="valueToAddRemoveBalance" type="number" :placeholder="$t('Valor')" />
        <small v-if="valueToAddRemoveBalance <= 0" class="text-danger mb-1">{{ $t('Informe um valor maior que zero') }}</small>

        <div class="d-row align-items-center mt-1">
          <!-- <b-form-radio v-model="selectSponsorship" name="some-radios" value="licenseContribution" class="mr-1">
            {{ $t("Patrocinar licença e aporte") }}
          </b-form-radio> -->
          <!-- <small class="text-danger" v-if="selectSponsorship === 'licenseContribution'">{{ $t('Atenção! Esta opção limita o rendimento em 30% e não sobe bônus') }}</small> -->
          <b-form-radio v-model="selectSponsorship" name="some-radios" value="license" class="mr-1 mt-1">
            {{ $t("Patrocinar somente a licença") }}
          </b-form-radio>
          <small class="text-danger" v-if="selectSponsorship === 'license'">{{ $t('Atenção! Esta opção não sobe bônus') }}</small>
          <b-form-radio v-model="selectSponsorship" name="some-radios" value="contribution" class="mr-1 mt-1">
            {{ $t("Patrocinar somente o aporte") }}
          </b-form-radio>
          <div class="p-2" v-if="selectSponsorship === 'contribution'">
            <small>{{ $t('Selecione a licença que gostaria de patrocinar o aporte') }}:</small>
            <b-form-radio v-model="licenseId" v-for="license in userLicenses" name="some-radios-2" :value="license._id" class="mr-1 mt-1">
              {{ license._id }} | {{ moneyFormat(license.licenseValue) }}
            </b-form-radio>
          </div>
          <!-- <b-form-radio v-model="selectSponsorship" name="some-radios" value="licenseContributionPIX" class="mr-1 mt-1">
            {{ $t("Ativar por PIX a licença e aporte") }}
          </b-form-radio> -->
          <small class="text-danger" v-if="selectSponsorship === 'licenseContributionPIX'">{{ $t('Atenção! Esta opção sobe bônus normalmente') }}</small>
          <b-form-radio v-model="selectSponsorship" name="some-radios" value="licensePIX" class="mr-1 mt-1">
            {{ $t("Ativar por PIX somente a licença") }}
          </b-form-radio>
          <small class="text-danger" v-if="selectSponsorship === 'licensePIX'">{{ $t('Atenção! Esta opção sobe bônus normalmente') }}</small>
          <b-form-radio v-model="selectSponsorship" name="some-radios" value="contributionPIX" class="mr-1 mt-1">
            {{ $t("Ativar por PIX somente o aporte") }}
          </b-form-radio>          
          <div class="p-2" v-if="selectSponsorship === 'contributionPIX'">
            <small>{{ $t('Selecione a licença que gostaria ativar o aporte por PIX') }}:</small>
            <b-form-radio v-model="licenseId" v-for="license in userLicenses" name="some-radios-2" :value="license._id" class="mr-1 mt-1">
              {{ license._id }} | {{ moneyFormat(license.licenseValue) }}
            </b-form-radio>
          </div>
          <b-form-radio v-model="selectSponsorship" name="some-radios" value="borrowedContribution" class="mr-1 mt-1">
            {{ $t("Emprestar o valor de aporte") }}
          </b-form-radio>   
          <small class="text-danger" v-if="selectSponsorship === 'borrowedContribution'">{{ $t('Atenção! Esta opção empresta o valor do aporte e cobra 30% dos bônus até o emprestimo ser pago') }}</small>       
          <div class="p-2" v-if="selectSponsorship === 'borrowedContribution'">
            <small>{{ $t('Selecione a licença que gostaria de emprestar o valor de aporte') }}:</small>
            <b-form-radio v-model="licenseId" v-for="license in userLicenses" name="some-radios-2" :value="license._id" class="mr-1 mt-1">
              {{ license._id }} | {{ moneyFormat(license.licenseValue) }}
            </b-form-radio>
          </div>
        </div>

        <div class="text-warning mt-1" v-if="selectSponsorship === 'licenseContribution' || selectSponsorship === 'licenseContributionPIX' || selectSponsorship === 'licensePIX' || selectSponsorship === 'license'">
          {{ selectSponsorship === 'licenseContributionPIX' || selectSponsorship === 'licensePIX' ? $t('Você vai ativar por PIX uma licença no valor de') : $t('Você vai patrocinar uma licença no valor de') }} {{ moneyFormat(selectSponsorship === 'licenseContribution' || selectSponsorship === 'licenseContributionPIX' ?
            valueToAddRemoveBalance * 0.25 : valueToAddRemoveBalance) }}
        </div>
        <div class="text-warning mt-1" v-if="selectSponsorship === 'licenseContribution' || selectSponsorship === 'licenseContributionPIX' || selectSponsorship === 'contributionPIX' || selectSponsorship === 'contribution'">
          {{ selectSponsorship === 'contributionPIX' || selectSponsorship === 'licenseContributionPIX' ? $t('Você vai ativar por PIX um aporte no valor de') : $t('Você vai patrocinar um aporte no valor de') }} {{ moneyFormat(selectSponsorship === 'licenseContribution' || selectSponsorship === 'licenseContributionPIX' ?
            valueToAddRemoveBalance * 0.75 : valueToAddRemoveBalance) }}
        </div>
        <div class="d-flex justify-content-between mt-2">
          <b-button variant="primary" block :disabled="loading || valueToAddRemoveBalance <= 0 || (selectSponsorship === 'contribution' || selectSponsorship === 'contributionPIX') && licenseId.length === 0"
            @click="() => addRemoveBalance(userToAddRemoveBalance, valueToAddRemoveBalance, 'add')">{{ loading ? $t('Aguarde...') : selectSponsorship === 'licenseContributionPIX' ? $t('Confirmar ativação por PIX') : $t('Confirmar patrocínio') }}</b-button>
        </div>
      </div>      
    </b-modal>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormTextarea,
  VBTooltip,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import { mapGetters, mapActions } from "vuex";
import { format } from "date-fns";
import { debounce } from "debounce";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, min, max } from "@/libs/validations";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,

    localize,

    vSelect,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      perPage: 100,
      pageOptions: [10, 25, 50, 100],
      totalRows: 0,
      currentPage: 1,
      entriesTo: 0,
      entriesFrom: 0,
      searchQuery: "",
      API_URL: process.env.VUE_APP_API_URL,
      usernameToDisable: "",
      reasonDisable: "",
      userToAddRemoveBalance: {},
      valueToAddRemoveBalance: 0,
      required,
      min,
      max,
      selectSponsorship: 'license',

      fields: [
        { key: "user", label: null },
        { key: "sponsoredLicense", label: null },
        { key: "sponsoredContribution", label: null },
        { key: "pixLicense", label: null },
        { key: "pixContribution", label: null },
        { key: "borrowedContribution", label: null },
        { key: 'lastUpdate', label: null },
        { key: "actions", label: null },
      ],
      items: [],
      params: {
        page: this.currentPage,
        limit: this.perPage,
        search: "",
        sortby: "createdAt",
        sortdirection: "desc",
      },
      sortByFilter: null,
      sortByOptions: [],
      sortDirectionFilter: null,
      sortDirectionOptions: [],
      licenseId: '',
      userLicenses: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["returnLocale", "returnBalanceManager"]),
    routerUserView() {
      return "";
    },
  },
  mounted() {
    this.renderUsersList();

    this.fields[0].label = this.$i18n.t("Usuário");
    this.fields[1].label = this.$i18n.t("Licença patrocinada");
    this.fields[2].label = this.$i18n.t("Aporte patrocinado");
    this.fields[3].label = this.$i18n.t("Licença PIX");
    this.fields[4].label = this.$i18n.t("Aporte PIX");
    this.fields[5].label = this.$i18n.t("Aporte Emprestado");
    this.fields[6].label = this.$i18n.t("Última atualização");
    this.fields[7].label = this.$i18n.t("Ação");

    this.sortByOptions = [
      { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
      { label: this.$i18n.t("E-mail"), value: "email" },
      { label: this.$i18n.t("Nome completo"), value: "fullname" },
      { label: this.$i18n.t("Saldo"), value: "balance" },
      { label: this.$i18n.t("Status"), value: "status" },
      { label: this.$i18n.t("Usuário"), value: "username" },
    ];

    this.sortDirectionOptions = [
      { label: this.$i18n.t("Crescente"), value: "asc" },
      { label: this.$i18n.t("Decrescente"), value: "desc" },
    ];
  },
  watch: {
    returnLocale: function (oldVal, newVal) {
      this.fields[0].label = this.$i18n.t("TxnID");
      this.fields[1].label = this.$i18n.t("Crypto");
      this.fields[2].label = this.$i18n.t("Valor USDT");
      this.fields[3].label = this.$i18n.t("Valor Crypto");
      this.fields[4].label = this.$i18n.t("Endereço Crypto");
      this.fields[5].label = this.$i18n.t("Status");
      this.fields[6].label = this.$i18n.t("Data");

      this.sortByOptions = [
        { label: this.$i18n.t("Data de cadastro"), value: "createdAt" },
        { label: this.$i18n.t("E-mail"), value: "email" },
        { label: this.$i18n.t("Nome completo"), value: "fullname" },
        { label: this.$i18n.t("Saldo"), value: "balance" },
        { label: this.$i18n.t("Status"), value: "status" },
        { label: this.$i18n.t("Usuário"), value: "username" },
      ];

      this.sortDirectionOptions = [
        { label: this.$i18n.t("Crescente"), value: "asc" },
        { label: this.$i18n.t("Decrescente"), value: "desc" },
      ];
    },
    returnBalanceManager: function (oldVal, newVal) {
      this.totalRows = oldVal.total;
      this.entriesTo =
        oldVal.perPage * oldVal.currentPage > oldVal.total
          ? oldVal.total
          : oldVal.perPage * oldVal.currentPage;
      this.entriesFrom =
        oldVal.perPage * oldVal.currentPage + 1 - oldVal.perPage;
      this.items = oldVal.usersWithPositiveBalance;
    },
    perPage: function (newVal) {
      this.params.limit = newVal;
      this.renderUsersList();
    },
    currentPage: function (newVal) {
      this.params.page = newVal;
      this.renderUsersList();
    },
    searchQuery: debounce(function (newVal) {
      this.params.search = newVal;
      this.renderUsersList();
    }, 500),
    statusFilter: function (newVal) {
      this.params.status = newVal;
      this.renderUsersList();
    },
    sortByFilter: function (newVal) {
      this.params.sortby = newVal;
      this.renderUsersList();
    },
    sortDirectionFilter: function (newVal) {
      this.params.sortdirection = newVal;
      this.renderUsersList();
    },
  },
  methods: {
    ...mapActions(["usersList", "sendEmailAPI", "disableUserAPI"]),
    getMostRecentSponsoredDate(array) {
        if (!array || array.length === 0) {
            return null;
        }

        // Array para armazenar todas as datas dos objetos 'sponsoredList'
        let allSponsoredDates = [];

        // Percorrer cada objeto no array principal
        array.forEach(mainObject => {
            if (mainObject.sponsoredList && mainObject.sponsoredList.length > 0) {
                // Extrair as datas do 'sponsoredList' e adicionar ao array 'allSponsoredDates'
                mainObject.sponsoredList.forEach(sponsored => {
                    allSponsoredDates.push(new Date(sponsored.date));
                });
            }
        });

        if (allSponsoredDates.length === 0) {
            return null;
        }

        // Encontrar a data mais recente
        const mostRecentDate = new Date(Math.max.apply(null, allSponsoredDates));

        return mostRecentDate.toISOString();
    },
    addRemoveBalanceModal(user) {
      this.userToAddRemoveBalance = user;
      this.$store
        .dispatch("adGetUserLicense", user._id).then((resp) => {
          this.userLicenses = resp;
          this.$bvModal.show("modal-add-remove-balance");
        })
    },
    addRemoveBalance(user, value, action) {
      this.loading = true;
      this.$store
        .dispatch("addRemoveBalance", {
          user_id: user._id,
          value: value,
          action: action,
          selectSponsorship: this.selectSponsorship,
          license_id: this.licenseId
        })
        .then((resp) => {
          this.userToAddRemoveBalance = {}
          this.valueToAddRemoveBalance = 0
          this.licenseId = '';
          this.$bvModal.hide("modal-add-remove-balance");
          this.renderUsersList();
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "success",
              title: resp,
            },
          });
          this.loading = false;
        })
        .catch(err => {
          this.userToAddRemoveBalance = {}
          this.valueToAddRemoveBalance = 0
          this.$bvModal.hide("modal-add-remove-balance");
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              icon: "CoffeeIcon",
              variant: "danger",
              title: err,
            },
          });
          this.loading = false;
        });
    },
    renderImgAvatar(img) {
      if (img)
        return img ? process.env.VUE_APP_API_URL + "/avatar/" + img : null;
    },

    userMaxRole(array) {
      let filtered = array.map((res) => res.role_id);
      return array.find((res) => res.role_id == Math.min(...filtered)).name;
    },

    renderUsersList() {
      this.$store.dispatch("fetchBalanceManager", this.params).then((resp) => {
        /** */
      });
    },

    sendEmail() {
      this.sendEmailAPI(this.usernameToDisable.id).then((response) => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            icon: "CoffeeIcon",
            variant: "success",
            title: this.$i18n.t("E-mail enviado!"),
            text: this.$i18n.t(
              "O e-mail de convite foi reenviado com sucesso."
            ),
          },
        });
      });
    },
  },
  setup() {
    format;

    const isAddNewUserSidebarActive = ref(false);

    const resolveUserRoleVariant = (role) => {
      if (role === "customer") return "primary";
      if (role === "employee_store") return "warning";
      if (role === "seller") return "success";
      if (role === "employee_adm") return "info";
      if (role === "administrator") return "danger";
      return "primary";
    };

    const resolveUserRoleIcon = (role) => {
      if (role === "customer") return "UserIcon";
      if (role === "employee_store") return "SettingsIcon";
      if (role === "seller") return "DatabaseIcon";
      if (role === "employee_adm") return "Edit2Icon";
      if (role === "administrator") return "ServerIcon";
      return "UserIcon";
    };

    const resolveUserStatusVariant = (status) => {
      if (status === "pending") return "warning";
      if (status === "active") return "success";
      if (status === "inactive") return "secondary";
      return "primary";
    };

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      // Utilis
      avatarText,
      format,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.limitString {
  white-space: nowrap;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
